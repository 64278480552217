import GlobalProvider from "./src/context/GlobalProvider"
import "./src/styles/global.css"
import React from 'react';
import { ClerkProvider} from '@clerk/clerk-react';



export const wrapPageElement = ({ element, props }) => (
  <ClerkProvider publishableKey={process.env.GATSBY_CLERK_PUBLISHABLE_KEY}>
  <GlobalProvider {...props}>{element}</GlobalProvider>
</ClerkProvider>
)
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  return prevRouterProps?.location?.pathname !== routerProps.location.pathname
}
