import React from "react"
import StateProvider from "./state/StateProvider"
import Helmet from "react-helmet"
import AuthenticationProvider from "./authentication/AuthenticationProvider"
import CartProvider from "./Cart/CartProvider"
// import ReportingProvider from "./Reporting/ReportingProvider"
import DataProvider from "./Data/DataProvider"
import NotificationProvider from "./Notification/NotificationProvider"
// import LocationProvider from "./Location/LocationProvider"
import { useLocation } from "@reach/router"
import SearchContextProvider from "./Search/searchProvider"
import OnSiteMarketingProvider from "./OnSiteMarketing/OnSiteMarketingProvider"
import {
  scriptGoogleTagManager,
  // scriptStonlyFirst,
  // scriptStonlySecond,
} from "../helpers/ScriptHelper"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"
import InventoryProvider from "./Inventory/InventoryProvider"
// import Maintenance from "../pages/maintenance"
TimeAgo.addDefaultLocale(en)
function GlobalProvider({ children }) {
  let location = useLocation()

  // if(true) return <Maintenance />

  return (
    <StateProvider>
      <AuthenticationProvider>
        {/* <LocationProvider> */}
        <NotificationProvider>
          {[
            "/login",
            "/login-sso",
            "/sso-callback",
            "/login_failed",
            "/register",
            "/reset-password",
            "/forgot-password",
          ].includes(location.pathname) ? (
            // === "/login" ||location.pathname === "/register"
            children
          ) : (
            <DataProvider>
              <InventoryProvider>
                <CartProvider>
                  {/* <ReportingProvider> */}
                  <OnSiteMarketingProvider>
                    <SearchContextProvider>
                      <Helmet>
                        <script>{scriptGoogleTagManager}</script>
                        {/* <script>{scriptStonlyFirst}</script>
                          <script>{scriptStonlySecond}</script> */}
                      </Helmet>
                      {children}
                    </SearchContextProvider>
                  </OnSiteMarketingProvider>
                  {/* </ReportingProvider> */}
                </CartProvider>
              </InventoryProvider>
            </DataProvider>
          )}
        </NotificationProvider>
        {/* </LocationProvider> */}
      </AuthenticationProvider>
    </StateProvider>
  )
}

export default GlobalProvider
